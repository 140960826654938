<template>
	<Link :href="'/'">
	<div class="dash-auth-logo-div flex items-center justify-center space-x-2 min-w-[25rem]">
		<img class="dash-auth-logo h-16 w-16" src="/assets/img/logos/dash_simple_icon.png" />
		<div class="dash-auth-tagline text-4xl align-middle text-white font-semibold pl-1">
			Dashboard
		</div>
	</div>
	</Link>
</template>

<script>
export default defineComponent({
	components: {
		Link
	},
	data: function () {
		return {
			publicPath: window.location.origin
		}
	},
	computed: {
		builtImageURL() {
			return this.publicPath + '/assets/img/logos/dash_logo_md.png';
		}
	}
});
</script>

<script setup>
import { Link } from '@inertiajs/inertia-vue3';
import { defineComponent } from 'vue';
</script>

<style scoped>
.auth-card-logo {
	padding: 1px;
}
</style>